import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Toolbar from '@/components/toolbar/toolbar';


export default {
    name: "Sustainability",
    data() {
        return {
            product_list: null,
            chk3: false,
            group_sustainability: [],
            image: null,
            list_sustainability: {},
            baseFront: this.$baseFront
        }
    },
    methods: {
        find_sustainability(id) {
            return this.list_sustainability.find(x => x.id == id)
        },
        gotoDetail(id) {
            this.$router.push(`subtainability-content/${id}`)
        },
        async fetchsustainability() {
            await this.$axios.get("/api/show/sustainability")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.list_sustainability = response.data.data
                    }
                })
        },
        async fetchGroupsustainability() {
            await this.$axios.get("/api/show/group_sustainability")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.group_sustainability = response.data.data
                    }
                })
        },
    },
    async mounted() {
        await this.fetchsustainability()
        await this.fetchGroupsustainability()
        document.title = "AGC - Sustainability";
        AOS.init();
    },
    components: {
        Footer, Header, Breadcrumb,Toolbar
    },
};